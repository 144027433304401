// these sizes are arbitrary and you can set them to whatever you wish
import { css } from 'styled-components'

export const sizes = {
  large: 1120,
  medium: 768,
  small: 0,
}

// iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = sizes[label] / 16
  accumulator[label] = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)}
    }
  `
  return accumulator
}, {})



export const getScreenType = () => {
  const windowAvailable = typeof window !== 'undefined'

  let mediaType = 'desktop'

  if (windowAvailable) {
    const screenWidth = window.innerWidth
    if (screenWidth < sizes.desktop) mediaType = 'tablet'
    if (screenWidth < sizes.tablet) mediaType = 'mobile'
  }

  return {
    isDesktop: mediaType === 'desktop',
    isTablet: mediaType === 'tablet',
    isMobile: mediaType === 'mobile',
  }
}