import React, { useEffect } from 'react'
import { navigate, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Headroom from 'react-headroom'
import { gsap } from 'gsap'

import { AnimatedLink, P } from '../shared/text'
import { media, getScreenType } from '../utils/style-utils'

import IDLogoSVG from '../shared/image-components/IDLogoSVG'

const Container = styled.nav`
  width: 100%;
  z-index: 100;

  display: grid;

  background-color: ${props => props.bgColor};

  ${media.small`
    padding: 18px 20px 14px 19px;
    grid-template-columns: 1fr auto;
    align-items: center;
  `}
  ${media.large`
    padding: 24px 33px 24px 42px;
    align-items: center;
  `}
`

const LogoContainer = styled.div`
  visibility: hidden;

  transition: 100ms transform ease-in-out;
  cursor: pointer;
  width: fit-content;

  & svg {
    ${media.small`
      width: 171px;
    `}
    ${media.large`
      width: 222px;
    `}
  }

  & svg > path {
    fill: ${props =>
      props.isDarkNav ? props.theme.colors.white : props.theme.colors.azure};
  }

  &:hover {
    & * {
      fill: ${props => props.theme.colors.healerite};
    }
  }
`

const LinkContainer = styled.div`
  display: none;

  ${media.medium`
    display: grid;
    grid-template-columns: repeat(${props => props.cols}, minmax(0, auto));
    grid-column-gap: 16px;
    justify-self: end;
    text-align: center;
  `}
  ${media.large`
    grid-template-columns: repeat(6, max-content);
    grid-column-gap: 26px;
  `}

  & > div {
    visibility: hidden;
  }
`

const LinkP = styled(P)`
  color: ${props =>
    props.isDarkNav
      ? props.theme.colors.white
      : props.active
      ? props.theme.colors.bluebird
      : props.theme.colors.azure};

  font-family: ${props => (props.active ? 'Apercu-Medium' : 'Apercu')},
    sans-serif;
    display: flex;
    align-items: center;
    gap: 4px;

  &:hover {
    color: ${props =>
      props.isDarkNav ? props.theme.colors.white : props.theme.colors.bluebird};
  }
`

const MobileMenuButton = styled.div`
  visibility: hidden;

  background-color: blue;
  width: 50px;
  height: auto;
  align-self: end;
  border-radius: 50%;

  ${media.medium`
    display: none;
  `}
`
const SubNavContainer = styled.div`
  position: relative;

  .sub-nav {
    opacity: 0;
    pointer-events: none;
    transition: all 0.4s;
    transform: translateY(-10px);
  }

  &:hover {
    .sub-nav {
      opacity: 1;
      transform: translateY(0);
      pointer-events: all;
    }
  }

  .main {
    cursor: pointer;
  }

`
const SubNav = styled.div`
  --padding: 7px;
  position: absolute;
  min-width: 175px;
  background-color: ${props =>
    props.isDarkNav
      ? props.theme.colors.azure
      : props.theme.colors.white};
  border: 1px solid ${props =>
    props.isDarkNav
      ? props.theme.colors.white
      : props.theme.colors.azure};
  border-radius: 8px;
  .sub-link {
    cursor: pointer;
    padding: 0 var(--padding);

    border-bottom: 1px solid ${props =>
    props.isDarkNav
      ? props.theme.colors.white
      : props.theme.colors.azure};
    &:last-child {
      padding:0 var(--padding) calc(var(--padding) - 5px);
      border-bottom: none;
    }
  }
`

const query = graphql`
  query {
    mobileMenuAzure: file(
      relativePath: { regex: "/navbar/mobile-menu--azure.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mobileMenuWhite: file(
      relativePath: { regex: "/navbar/mobile-menu--white.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Navbar = ({ pathname, links, openMobileMenu }) => {
  const { mobileMenuAzure, mobileMenuWhite } = useStaticQuery(query)
  const { isTablet, isDesktop } = getScreenType()

  useEffect(() => {
    const tl = gsap.timeline({ delay: 0.2, defaults: { ease: 'power4.out' } })

    tl.to('.navbar__img', { visibility: 'visible' }, '<').from('.navbar__img', {
      opacity: 0,
      duration: 0.5,
    })

    if (isTablet || isDesktop) {
      tl.to('.navbar__link', { visibility: 'visible' }, '<').from(
        '.navbar__link',
        {
          opacity: 0,
          y: -40,
          duration: 0.35,
          stagger: 0.15,
        },
        '<'
      )
    }
  }, [isTablet, isDesktop])

  const linkCount = links.length + 1 // Adds 1 manually for blog link

  // Determines Navbar bg color based on pathname
  let bgColor = 'white'

  const blueNav = ['portfolio', 'team', 'career']
  const isBlueNav = blueNav.some(path => pathname.includes(path))
  if (isBlueNav) bgColor = props => props.theme.colors.azure

  const grayNav = ['press']
  const isGrayNav = grayNav.some(path => pathname.includes(path))
  if (isGrayNav) bgColor = props => props.theme.colors.gandalf

  // Determines whether Navbar copy should be dark or white
  const isDarkNav = isBlueNav

  const displayedLogo = isDarkNav ? mobileMenuWhite : mobileMenuAzure

  const handleLinkClick = ({ external = false, path }) => {
    if (external) window.location.href = path
    else navigate(path)
  }

  return (
    <Headroom style={{ zIndex: 100, position: 'fixed' }}>
      <Container isDarkNav={isDarkNav} bgColor={bgColor}>
        <LogoContainer
          isDarkNav={isDarkNav}
          onClick={() => navigate('/')}
          className="navbar__img"
        >
          <IDLogoSVG />
        </LogoContainer>

        <MobileMenuButton onClick={openMobileMenu} className="navbar__img">
          <Img
            fluid={displayedLogo.childImageSharp.fluid}
            alt="Open mobile menu"
            img={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
            loading="lazy"
          />
        </MobileMenuButton>

        <LinkContainer cols={linkCount}>
          {links.map(link => (
            <div className="navbar__link" key={link.label}>
              {link.subnav ? (
                <SubNavContainer>
                    <LinkP
                      isDarkNav={isDarkNav}
                      className="main"
                      active={link.path === pathname}
                    >
                      {link.label.toLowerCase()}
                      <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M-1.01147e-06 1.32213C-9.967e-07 0.984303 0.109199 0.646476 0.327599 0.386964C0.764399 -0.128989 1.4729 -0.128989 1.9097 0.386964L6.5 5.80908L11.0903 0.386965C11.5271 -0.128988 12.2356 -0.128988 12.6724 0.386965C13.1092 0.902918 13.1092 1.73981 12.6724 2.25576L7.2917 8.61304C7.0811 8.86026 6.7977 9 6.5 9C6.2023 9 5.9189 8.86026 5.7083 8.61304L0.327598 2.25576C0.109198 1.99778 -1.02623e-06 1.65996 -1.0114e-06 1.32059L-1.01147e-06 1.32213Z" fill="currentColor"/>
                      </svg>

                    </LinkP>
                  <SubNav isDarkNav={isDarkNav} className="sub-nav">
                    {link.subnav.map(sublink => (
                      <LinkP
                        onClick={() => handleLinkClick(sublink)}
                        isDarkNav={isDarkNav}
                        className="sub-link"
                        active={sublink.path === pathname}
                        key={sublink.label}
                      >
                        {sublink.label.toLowerCase()}
                      </LinkP>
                    ))}
                  </SubNav>
                </SubNavContainer>
              ) : (
                <AnimatedLink active={link.path === pathname}>
                  <LinkP
                    onClick={() => handleLinkClick(link)}
                    isDarkNav={isDarkNav}
                    active={link.path === pathname}
                  >
                    {link.label.toLowerCase()}
                  </LinkP>
                </AnimatedLink>
              )}
            </div>
          ))}
        </LinkContainer>
      </Container>
    </Headroom>
  )
}

export default Navbar
