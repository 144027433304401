import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        ogImage
      }
    }
  }
`

const SEO = ({ description, keywords, title, image }) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaTitle = title || data.site.siteMetadata.title
        const metaImage = image || data.site.siteMetadata.ogImage
        const metaDescription =
          description || data.site.siteMetadata.description

        return (
          <Helmet
            htmlAttributes={{
              lang: 'en',
            }}
            title={metaTitle}
            titleTemplate="%s — Innovation Department"
            defaultTitle={data.site.siteMetadata.title}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: metaImage,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: `${title} — Innovation Department`,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ].concat(
              keywords && keywords.length > 0
                ? { name: `keywords`, content: keywords.join(`, `) }
                : []
            )}
          />
        )
      }}
    />
  )
}

export default SEO
