import React, { useState } from 'react'
import styled, {
  createGlobalStyle,
  ThemeProvider,
  keyframes,
} from 'styled-components'

import Navbar from '../Navbar'
import Footer from '../Footer'
import NavDrawer from '../Navbar/NavDrawer'

import { media } from '../utils/style-utils.js'

import '../../styles/typography.css'

const theme = {
  colors: {
    // ID brand colors
    azure: '#1325A9',
    bluebird: '#132675',
    healerite: '#AECC02',
    alabaster: '#F3F1EA',
    gandalf: '#F5F5F5',
    elephant: '#E5E5E5',

    // Basic colors
    white: '#FFFFFF',
    black: '#OAOAOA',
  },
}

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    line-height: 1.3;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    min-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden;

    font-family: Apercu, sans-serif;
    scroll-behavior: smooth;
  }
  
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  
  html,
  body,
  p,
  ol,
  ul,
  li,
  dl,
  dt,
  dd,
  blockquote,
  figure,
  fieldset,
  legend,
  textarea,
  pre,
  iframe,
  hr,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;

    &::selection {
      color: ${theme.colors.azure};
      background: ${theme.colors.healerite};
    }
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: 100%;
    font-weight: normal;
  }
  
  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
  }
`

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const LayoutContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;

  animation: ${fadeIn} linear 1s;
  -webkit-animation: ${fadeIn} linear 1s;
  -moz-animation: ${fadeIn} linear 1s;
  -o-animation: ${fadeIn} linear 1s;
  -ms-animation: ${fadeIn} linear 1s;
`

const MainContainer = styled.main`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  margin-top: -2px;
`

const MobileOnly = styled.div`
  ${media.medium`
    display: none;
  `}
`

const Layout = ({ children, location }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const navigationLinks = [
    { label: 'About Us', path: '/about' },
    { label: 'Portfolio',
      path: '/portfolio/built',
      subnav: [
        { label: 'Built', path: '/portfolio/built' },
        { label: 'Invested', path: '/portfolio/invested' }
      ] 
    },
    { label: 'Founders', path: '/founders' },
    { label: 'Press', path: '/press' },
    { label: 'Join Us', path: '/careers' },
    // {
    //   label: 'Blog',
    //   path: 'https://medium.com/the-edit-by-innovation-department',
    //   external: true,
    // },
  ]

  const openMobileMenu = () => setMobileMenuOpen(true)
  const closeMobileMenu = () => setMobileMenuOpen(false)

  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <LayoutContainer>
          <Navbar
            pathname={location.pathname}
            links={navigationLinks}
            openMobileMenu={openMobileMenu}
          />
          <MainContainer>{children}</MainContainer>
          <Footer links={navigationLinks} />
        </LayoutContainer>

        <MobileOnly>
          <NavDrawer
            links={navigationLinks}
            open={mobileMenuOpen}
            closeMobileMenu={closeMobileMenu}
          />
        </MobileOnly>
      </ThemeProvider>
    </>
  )
}

export default Layout
