import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import EmailCapture from './EmailCapture'
import IDLightbulbSVG from '../shared/image-components/IDLightbulbSVG'
import { SmallP, P, AnimatedLink } from '../shared/text'
import { media } from '../utils/style-utils'

const FooterContainer = styled.footer`
  background-color: ${props => props.theme.colors.gandalf};
  display: grid;
  place-items: center;

  ${media.small`
    padding: 43px 20px 22px 20px;
  `}
  ${media.large`
    padding: 50px 102px 23px 102px;
  `}
`

const Grid = styled.div`
  width: 100%;
  display: grid;
  place-items: center;
  margin: 0 auto;

  & p {
    color: ${props => props.theme.colors.azure};
    white-space: pre-line;
  }

  ${media.small`
    grid-template-columns: 1fr;
    max-width: 300px;

    & p {
      text-align: center;
    }
  `}
  ${media.large`
    grid-template-columns: 1fr auto;
    grid-column-gap: 80px;
    max-width: 1200px;

    & p {
      text-align: left;
    }
  `}
`

const DesktopTextColumnGrid = styled.div`
  width: fit-content;
  align-self: start;
  justify-content: start;

  ${media.small`
    display: none;
  `}
  ${media.large`
    display: grid;
    grid-template-columns: repeat(2, max-content);
    margin-top: 40px;
    grid-gap: 22px 50px;
  `}

  & p {
    color: ${props => props.theme.colors.azure};
    cursor: pointer;
    font-size: 18px;
    line-height: 1;
  }
`

const MobileTextColumnGrid = styled(DesktopTextColumnGrid)`
  width: 100%;

  ${media.small`
    display: grid;
    place-items: center;
    text-align: center;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 19px 16px;
    margin: 40px auto;
  `}
  ${media.large`
    display: none;
  `}
`

const LogoWrapper = styled.div`
  cursor: pointer;
  transition: 250ms ease-in-out all;
  width: fit-content;

  ${media.small`
    height: 50px;
  `}
  ${media.large`
    height: 50px;
    margin-bottom: 28px;
  `}

  &:hover {
    transform: translate(0, -6px);
    & svg > path {
      fill: ${props => props.theme.colors.healerite};
    }
  }
`

const CopyrightP = styled(SmallP)`
  font-size: 14px !important;
  line-height: 1;

  ${media.small`
    margin-top: 30px;
  `}
  ${media.large`
    margin-top: 71px;
  `}
`

const EmailWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  align-items: end;

  grid-row: 1;

  ${media.small`
    justify-self: center;
  `}
  ${media.large`
    justify-self: start;
  `}
`

const LeftGroup = styled(EmailWrapper)`
  ${media.small`
    grid-row: 2;
    grid-gap: 21px 30px;
  `}
  ${media.large`
    grid-template-columns: max-content 1fr;
    grid-row: 1;
    grid-gap: 0 41px;
  `}
`

const DesktopSmallP = styled(SmallP)`
  color: ${props => props.theme.colors.azure};
  width: fit-content;

  ${media.small`
    display: none;
  `}
  ${media.large`
    display: block;
  `}
`

const LogoAddressContainer = styled.div`
  display: grid;

  ${media.small`
    justify-content: center;
    margin-top: 12px;

    & p {
      display: none;
    }
  `}
  ${media.large`
    justify-content: start;
    margin-top: 0px;

    & p {
      display: initial;
    }
  `}
`

const MobileAddressGroup = styled.div`
  text-align: center;
  font-size: 14px !important;
  color: ${props => props.theme.colors.azure};

  ${media.large`
    display: none
  `}
`

const Footer = ({ links }) => {
  const handleClick = ({ external = false, path }) => {
    if (external) window.location.href = path
    else navigate(path)
  }

  const address = `100 Crosby St, Suite 506\nNew York, NY 10012`
  const copyright = 'Copyright 2021 Innovation Brands Corp.'

  return (
    <FooterContainer>
      <Grid>
        <LeftGroup>
          <LogoAddressContainer>
            <LogoWrapper onClick={() => navigate('/')}>
              <IDLightbulbSVG />
            </LogoWrapper>
            <SmallP>{address}</SmallP>
            <CopyrightP>{copyright}</CopyrightP>
          </LogoAddressContainer>

          <DesktopTextColumnGrid>
            {links.map(link => (
              <AnimatedLink key={link.label}>
                <P onClick={() => handleClick(link)}>{link.label}</P>
              </AnimatedLink>
            ))}
          </DesktopTextColumnGrid>
        </LeftGroup>

        <EmailWrapper>
          <EmailCapture />
          <AnimatedLink>
            <DesktopSmallP
              as="a"
              href="mailto:info@innovationdept.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <b>info@innovationdept.com</b>
            </DesktopSmallP>
          </AnimatedLink>
        </EmailWrapper>

        <MobileTextColumnGrid>
          {links.map(link => (
            <AnimatedLink key={link.label}>
              <P onClick={() => handleClick(link)}>{link.label}</P>
            </AnimatedLink>
          ))}
        </MobileTextColumnGrid>

        <MobileAddressGroup>
          <SmallP>{address}</SmallP>
          <br />
          <SmallP
            as="a"
            href="mailto:info@innovationdept.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <b>info@innovationdept.com</b>
          </SmallP>
          <CopyrightP>{copyright}</CopyrightP>
        </MobileAddressGroup>
      </Grid>
    </FooterContainer>
  )
}

export default Footer
