import React from 'react'
import styled from 'styled-components'

import { media } from '../utils/style-utils.js'

export const H1 = styled.h1`
  font-family: Bossa, sans-serif;
  color: inherit;
  text-align: inherit;
  font-weight: 500;

  ${media.small`
    font-size: 40px;
    line-height: 60px;

    &::after {
      content: ".";
      font-size: 80px;
      line-height: 0;
      color: ${props => props.theme.colors.healerite};
      padding: 0 0 0 8px;
      bottom: -10px;
    }
  `}
  ${media.medium`
    font-size: 55px;
    line-height: 75px;

    &::after {
      font-size: 110px;
    }
  `}
  ${media.large`
    font-size: 80px;
    line-height: 120px;
  `}


`

export const H2 = styled.h2`
  font-family: Bossa, sans-serif;
  color: inherit;
  text-align: inherit;
  font-weight: 500;

  ${media.small`
    font-size: 30px;
    line-height: 42px;
  `}
  ${media.large`
    font-size: 42px;
    line-height: 63px;
  `}
`

export const LargeP = styled.p`
  font-family: Apercu, sans-serif;
  color: inherit;
  text-align: inherit;
  font-weight: 500;

  ${media.small`
    font-size: 24px;
    line-height: 34px;
  `}
  ${media.medium`
    font-size: 30px;
    line-height: 42px;
  `}
  ${media.large`
    font-size: 36px;
    line-height: 54px;
  `}
`

export const P = styled.p`
  font-family: Apercu, sans-serif;
  color: inherit;
  text-align: inherit;

  ${media.small`
    font-size: 18px;
    line-height: 34px;
  `}
  ${media.medium`
    font-size: 20px;
    line-height: 34px;
  `}
  ${media.large`
    font-size: 24px;
    line-height: 36px;
  `}

  & b {
    font-family: Apercu-Medium, sans-serif !important;
    font-weight: 500;
  }
`

export const SmallP = styled(P)`
  ${media.small`
    font-size: 16px;
    line-height: 24px;
  `}
  ${media.medium`
    font-size: 18px;
    line-height: 28px;
  `}
`

export const Gotham = styled(P)`
  font-family: Gotham, sans-serif;
  font-weight: 700;

  ${media.small`
    font-size: 13px;
    line-height: 15px;
  `}
  ${media.medium`
    font-size: 13px;
    line-height: 15px;
  `}
  ${media.large`
    font-size: 13px;
    line-height: 15px;
  `}
`

const LinkWrapper = styled.div`
  position: relative;

  cursor: ${props => (props.active ? 'default' : 'pointer')};
  
  display: block;
  width: 100%;
  height: fit-content;
  text-align: inherit;

  color: inherit;

  ${media.large`
    &:hover {
      & div::after {
        width: 100%;
        transition: 0.3s ease all;
      }
    }
  `}

  & > div {
    position: relative;
    display: inline-block;
    width: fit-content;
    margin: 0;
    padding: 0 2px 2px 2px;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }

    &::after {
      content: '';
      position: absolute;
      height: 2px;
      bottom: 0;
      left: 0%;
      background-color: ${props => props.theme.colors.healerite};
      transition: 0.2s ease all;
      width: ${props => (props.active ? '100%' : 0)};
    }
  }
`

export const AnimatedLink = ({ children, active }) => (
  <LinkWrapper active={active}>
    <div>{children}</div>
  </LinkWrapper>
)
