import React, { useState } from 'react'
import styled from 'styled-components'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import { Button } from '../shared/buttons'
import { SmallP, Gotham } from '../shared/text'
import { media } from '../utils/style-utils.js'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;

  ${media.small`
    align-items: center;

    & button {
      width: 300px;
      height: 44px;
      font-size: 18px;
      margin-top: 22px;
    }
  `}
  ${media.large`
    align-items: flex-start;
    align-self: end;
    max-width: 475px;

    & button {
      width: fit-content;
      height: 40px;
      margin-top: 0;
    }
  `}
`

const FormContainer = styled(Container)`
  ${media.large`
    flex-direction: row;
    margin-top: 18px;
  `}
`

const FormInput = styled.input`
  box-sizing: border-box;

  background-color: transparent;
  border: ${props =>
    props.error
      ? `2px solid ${props.theme.colors.healerite}`
      : `1px solid ${props.theme.colors.azure}`};
  color: ${props => props.theme.colors.azure};
  border-radius: 10px;
  outline: none;
  height: 40px;
  padding: 0 30px;

  &:focus,
  &:active {
    background-color: ${props => props.theme.colors.white};
  }

  ::placeholder {
    color: ${props => props.theme.colors.azure};
    font-size: 16px;
  }

  ${media.small`
    text-align: center;
    margin: 22px 0 0 0;
    font-size: 16px;
    width: 300px;
  `}
  ${media.large`
    text-align: left;
    margin: 0 12px 0 0;
    font-size: 16px;
    width: 100%;
  `}
`

const ErrorP = styled(Gotham)`
  color: ${props => props.theme.colors.healerite} !important;

  font-size: 12px !important;
  line-height: 1.3 !important;
  margin-top: 10px;
  height: 15px;
`

const StyledSmallP = styled(SmallP)`
  &:first-of-type {
    margin-bottom: 10px;
  }

  ${media.small`
    font-size: 18px;
    line-height: 28px;
  `}
  ${media.large`
    font-size: 18px;
    line-height: 28px;
  `}
`

const EmailCapture = () => {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [signupRes, setSignupRes] = useState('')

  const handleChange = e => {
    setEmail(e.target.value)
    setError(false)
    setSignupRes('')
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const response = await addToMailchimp(email)

    if (response.result === 'success') {
      setEmail('')
      setError(false)
      setSignupRes(response.msg)
    } else {
      setError(true)

      const splitKeys = [' to list'] // Already subscribed

      let shortMsg = response.msg

      splitKeys.forEach(key => {
        if (response.msg.includes(key)) shortMsg = response.msg.split(key)[0]
      })

      setSignupRes(shortMsg)
    }
  }

  const handleKeyPress = e => {
    console.log(e.key)
    if (e.key === 'Enter') handleSubmit(e)
  }

  return (
    <Container>
      <StyledSmallP>
        <b>Stay in Touch</b>
      </StyledSmallP>
      <StyledSmallP>
        Sign up to receive our newsletter and important updates!
      </StyledSmallP>
      <FormContainer>
        <FormInput
          placeholder="Stay in touch"
          onChange={handleChange}
          value={email}
          error={error}
          onKeyPress={handleKeyPress}
        />
        <Button onClick={handleSubmit}>subscribe</Button>
      </FormContainer>
      <ErrorP>{signupRes || ''}</ErrorP>
    </Container>
  )
}

export default EmailCapture
