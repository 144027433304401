import styled from 'styled-components'

export const Button = styled.button`
  height: 37px;
  width: fit-content;

  font-family: Apercu, sans-serif;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  border: none;
  outline: none;
  border-radius: 50px;
  line-height: 1;

  font-size: 24px;
  padding: 5px 16px 6px 16px;

  background-color: ${props => props.theme.colors.azure};
  border: 1px solid ${props => props.theme.colors.azure};
  color: ${props => props.theme.colors.white};

  &:hover {
    border: 1px solid ${props => props.theme.colors.healerite};
    background-color: ${props => props.theme.colors.healerite};
    color: ${props => props.theme.colors.azure};
  }
`

export const WhiteButton = styled(Button)`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.azure};
  border: 1px solid ${props => props.theme.colors.azure};
  font-size: 18px;
`

export const FilterButton = styled(WhiteButton)`
  padding: 0 54px;
  height: 65px;
  display: flex;
  justify-items: center;
  align-items: center;

  margin-top: 80px;
`
