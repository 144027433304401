import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import Drawer from '@material-ui/core/Drawer'
import Img from 'gatsby-image'
import { useLockBodyScroll } from 'react-use'

import { H2 } from '../shared/text'

const BlueContainer = styled.div`
  min-width: calc(100vw + 20px);
  min-height: calc(100vh);
  background-color: ${props => props.theme.colors.azure};

  padding: 20px 20px 20px 40px;
`

const Grid = styled.div`
  width: 100%;
  height: fit-content;

  display: grid;
  grid-row-gap: 30px;

  & h2 {
    width: fit-content;
    font-size: 40px !important;
    color: ${props => props.theme.colors.white};
  }

  & h2:active {
    color: ${props => props.theme.colors.healerite};
  }
`

const CloseButton = styled.div`
  background-color: blue;
  height: auto;
  width: 50px;
  justify-self: end;
  border-radius: 50%;
  cursor: pointer;
`

const SubNav = styled.div`
  margin-left: 30px;
  display: grid;
  grid-row-gap: 30px;
`

const query = graphql`
  query {
    closeIcon: file(
      relativePath: { regex: "/navbar/nav-menu-close--white.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const NavDrawer = ({ links, open, closeMobileMenu }) => {
  const { closeIcon } = useStaticQuery(query)

  useLockBodyScroll(open)

  const handleClick = ({ external = false, path }) => {
    closeMobileMenu()
    if (external) window.location.href = path
    else navigate(path)
  }

  return (
    <Drawer anchor="right" open={open} onClose={closeMobileMenu}>
      <BlueContainer>
        <Grid>
          <CloseButton onClick={closeMobileMenu}>
            <Img
              fluid={closeIcon.childImageSharp.fluid}
              alt="Open mobile menu"
              img={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          </CloseButton>
          {links.map(link => (
            <React.Fragment key={link.label}>
              <H2 onClick={() => handleClick(link)}>
                {link.label.toLowerCase()}
              </H2>
              {link.subnav && (
                <SubNav>
                  {link.subnav.map(sublink => (
                    <H2 key={sublink.label} onClick={() => handleClick(sublink)}>
                      {sublink.label.toLowerCase()}
                    </H2>
                  ))}
                </SubNav>
              )}
            </React.Fragment>
          ))}
        </Grid>
      </BlueContainer>
    </Drawer>
  )
}

export default NavDrawer
